import * as React from 'react'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { useParams } from 'react-router'
import { UpcomingRacesTable } from '../common/UpcomingRacesTable'
import { RaceCardsWrapper } from '../generic_real_time_tracker/RaceCardsWrapper'
import GenericHistoricalTrackerContainer from './GenericHistoricalTracker'
import { LearningCenterGuideForTracker } from './LearningCenterGuide'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    fullWidth: {
        width: '100%',
    },
    trackerName: {
        flexGrow: 1,
    },
}))

export const GenericRealTimeTrackerComponent = () => {
    const [howToTrackIsOpen, setHowToTrackIsOpen] = useState(false)
    const [assignMeRaces, setAssignMeRaces] = useState(false)
    const [onlyAssignedRaces, setOnlyAssignedRaces] = useState(true)
    const { trackerName }: { trackerName: string } = useParams()
    const tracker = useSelector((state: RootStateType) =>
        state?.realTimeTrackersStore?.realTimeTrackers?.find((t) => t.Name.split(' ').join('_') === trackerName)
    )
    const team = useSelector((state: RootStateType) => state?.meStore?.me?.team)
    const active = useSelector((state: RootStateType) => state?.meStore?.me?.active)
    const historicalTrackers = useSelector((state: RootStateType) => state?.historicalTrackersStore?.historicalTrackers)
    const [showHistoricalTrackers, setShowHistoricalTrackers] = useState(false)
    const classes = useStyles()
    const dispatch = useDispatch()
    const effectivePracticeMode = (tracker?.PracticeMode ?? false) || !active
    const isMobile = (window as any).isMobileDevice()

    useEffect(() => {
        if (!effectivePracticeMode && !onlyAssignedRaces && team !== 'admin') {
            setOnlyAssignedRaces(true)
        }
        if (effectivePracticeMode) {
            setOnlyAssignedRaces(false)
            setAssignMeRaces(false)
        }
    }, [active, onlyAssignedRaces, effectivePracticeMode, dispatch, team])

    return (
        <>
            <Grid container direction={'column'} spacing={1} className={classes.root} alignItems={'center'}>
                {!tracker && (
                    <Grid item>
                        <Typography variant={'h4'}>tracker not found</Typography>
                    </Grid>
                )}
                {tracker && (
                    <>
                        <Grid item className={classes.fullWidth}>
                            <Grid
                                container
                                direction={'row'}
                                justifyContent={'center'}
                                spacing={1}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    {!effectivePracticeMode && (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={assignMeRaces}
                                                    onChange={() => setAssignMeRaces(!assignMeRaces)}
                                                    name="assign-me-races-toggle"
                                                    color="primary"
                                                />
                                            }
                                            label="Assign Me Races"
                                        />
                                    )}
                                </Grid>
                                <Grid item className={classes.trackerName}>
                                    <Grid container direction={'row'} justifyContent={'center'}>
                                        <Grid item>
                                            <Typography variant={'h4'}>
                                                {tracker.Name}
                                                {effectivePracticeMode && ' (Practice Mode)'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {historicalTrackers && historicalTrackers.length > 0 && (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showHistoricalTrackers}
                                                    onChange={() => setShowHistoricalTrackers(!showHistoricalTrackers)}
                                                    name="show-historical-trackers-toggle"
                                                    color="primary"
                                                />
                                            }
                                            label="Show Historical Tracking"
                                        />
                                    </Grid>
                                )}
                                {team === 'admin' && !effectivePracticeMode && (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={onlyAssignedRaces}
                                                    onChange={() => setOnlyAssignedRaces(!onlyAssignedRaces)}
                                                    name="only-assigned-races-toggle"
                                                    color="primary"
                                                />
                                            }
                                            label="Only assigned races"
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant={'body1'}>
                                        <Button
                                            color={'default'}
                                            variant={'text'}
                                            size={'small'}
                                            onClick={() => setHowToTrackIsOpen(true)}
                                        >
                                            How to track?
                                        </Button>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Dialog open={howToTrackIsOpen} onClose={() => setHowToTrackIsOpen(false)}>
                                        <DialogTitle id="customized-dialog-title">
                                            How to track {tracker.Name}
                                        </DialogTitle>
                                        <DialogContent dividers>
                                            {tracker.GuideURL && tracker.GuideURL.length > 0 && (
                                                <LearningCenterGuideForTracker tracker={tracker} />
                                            )}
                                            {!(tracker.GuideURL && tracker.GuideURL.length > 0) && (
                                                <Typography variant={'h6'}>
                                                    No Guide, please reach out to Will
                                                </Typography>
                                            )}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                autoFocus
                                                onClick={() => setHowToTrackIsOpen(false)}
                                                color="primary"
                                            >
                                                Got it!
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <RaceCardsWrapper
                                tracker={tracker}
                                assignMeRaces={assignMeRaces}
                                onlyAssignedRaces={onlyAssignedRaces && !isMobile}
                            />
                        </Grid>
                        {tracker && (
                            <UpcomingRacesTable
                                upcomingRacesParams={{
                                    dispatch: dispatch,
                                    mtp: 25,
                                    raceType: tracker.RaceType,
                                    onlyAssigned: false,
                                    trackingType: '',
                                    country: tracker.Country,
                                }}
                            />
                        )}
                    </>
                )}
            </Grid>
            {historicalTrackers && historicalTrackers.length > 0 && showHistoricalTrackers && (
                <Grid
                    container
                    direction={'column'}
                    spacing={1}
                    className={classes.root}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Grid item>
                        <GenericHistoricalTrackerContainer />
                    </Grid>
                </Grid>
            )}
        </>
    )
}
