import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { getErrorReducer } from './reducers/get_error'
import { getMeReducer } from './reducers/get_me'
import { getLatencyReducer } from './reducers/get_latency'
import { StateType } from 'typesafe-actions'
import { composeWithDevTools } from 'redux-devtools-extension'
import { getDarkModeReducer } from './reducers/get_dark_mode'
import { getHistoricalTrackersReducer } from './reducers/get_historical_trackers'
import { getRealTimeTrackersReducer } from './reducers/get_real_time_trackers'
import { getTrackerAssessmentsReducer } from './reducers/get_tracker_assessments'
import { getActionCommentsReducer } from './reducers/get_action_comments'
import { getTogglesReducer } from './reducers/get_toggles'
import { getActionReviewsReducer } from './reducers/get_action_reviews'

export const history = createBrowserHistory()

const rootReducer = combineReducers({
    errorStore: getErrorReducer,
    latencyStore: getLatencyReducer,
    meStore: getMeReducer,
    router: connectRouter(history),
    darkModeStore: getDarkModeReducer,
    historicalTrackersStore: getHistoricalTrackersReducer,
    realTimeTrackersStore: getRealTimeTrackersReducer,
    trackerAssessmentsStore: getTrackerAssessmentsReducer,
    userActionCommentsStore: getActionCommentsReducer,
    togglesStore: getTogglesReducer,
    actionReviewsStore: getActionReviewsReducer,
})

export type RootStateType = StateType<typeof rootReducer>

export const createReduxStore = () => {
    const middleware = routerMiddleware(history)
    const rootReducer = combineReducers({
        errorStore: getErrorReducer,
        latencyStore: getLatencyReducer,
        meStore: getMeReducer,
        router: connectRouter(history),
        darkModeStore: getDarkModeReducer,
        historicalTrackersStore: getHistoricalTrackersReducer,
        realTimeTrackersStore: getRealTimeTrackersReducer,
        trackerAssessmentsStore: getTrackerAssessmentsReducer,
        userActionCommentsStore: getActionCommentsReducer,
        togglesStore: getTogglesReducer,
        actionReviewsStore: getActionReviewsReducer,
    })
    return createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(
                middleware
                // ... other middlewares ...
            )
        )
    )
}
